<template>
  <div class="border mb-3 p-3">
    <div class="mb-3">
      <span class="tw-border-start fw-bolder me-3">結單優惠</span>
      <button class="tw-btn tw-btn-danger" @click="selectStoreDiscount = null">
        不選擇結單優惠
      </button>
    </div>
    <div class="table-responsive" v-if="!storeDiscounts.loading">
      <table class="table table-hover" v-if="storeDiscounts.data.length > 0">
        <thead>
          <tr>
            <th>選擇</th>
            <th class="text-start">促銷名稱</th>
            <th class="text-start">促銷內容</th>
            <th class="text-start">促銷限制</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in storeDiscounts.data" :key="data.id">
            <td>
              <input
                type="radio"
                name="discount"
                :value="data"
                v-model="selectStoreDiscount"
              />
            </td>
            <td class="text-start">{{ data.name }}</td>
            <td class="text-start">
              <div
                v-if="
                  data.storeDiscountConditions[0].discountType ===
                  'FULL_AMOUNT_REBATE'
                "
              >
                <p>
                  滿{{
                    data.storeDiscountConditions[0].threshold
                  }}元，總金額折抵{{
                    data.storeDiscountConditions[0].discount
                  }}元<span v-if="data.storeDiscountConditions[0].progressive"
                    >，採累進折抵</span
                  >
                </p>
                <p v-if="data.storeDiscountConditions[0].progressive">
                  (滿{{ data.storeDiscountConditions[0].threshold * 2 }}元折抵{{
                    data.storeDiscountConditions[0].discount * 2
                  }}元，以此類推)
                </p>
              </div>
              <!-- 滿額打折 -->
              <div
                v-else-if="
                  data.storeDiscountConditions[0].discountType ===
                  'FULL_AMOUNT_DISCOUNT'
                "
              >
                <p>
                  滿{{
                    data.storeDiscountConditions[0].threshold
                  }}元，總金額打{{ data.storeDiscountConditions[0].discount }}折
                </p>
              </div>
              <!-- 滿額免運 -->
              <div
                v-else-if="
                  data.storeDiscountConditions[0].discountType ===
                  'FULL_AMOUNT_REBATE_SHIPPING_COST'
                "
              >
                <p>
                  滿{{ data.storeDiscountConditions[0].threshold }}元，免運費
                </p>
              </div>
              <!-- 滿件免運 -->
              <div
                v-else-if="
                  data.storeDiscountConditions[0].discountType ===
                  'FULL_QUANTITY_REBATE_SHIPPING_COST'
                "
              >
                <p>
                  滿{{ data.storeDiscountConditions[0].threshold }}件，免運費
                </p>
              </div>
            </td>
            <td class="text-start">
              <p v-if="!data.storeVip && data.customerGroups.length === 0">
                會員皆可使用
              </p>
              <template v-else>
                <!-- vip 限制 -->
                <div title="VIP限制" class="mb-2" v-if="data.storeVip">
                  <img class="icon me-2" src="@/assets/icon/crown1.png" />
                  <span> {{ data.storeVip.name }} 以上</span>
                </div>
                <!-- 顧客群組限制 -->
                <div title="顧客群組限制" v-if="data.customerGroups.length > 0">
                  <img class="icon me-2" src="@/assets/icon/users.png" />
                  <span
                    v-for="(customerGroup, key) in data.customerGroups"
                    :key="customerGroup.id"
                  >
                    {{ customerGroup.name }}
                    <span v-if="key !== data.customerGroups.length - 1">
                      、
                    </span>
                  </span>
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="text-danger fw-bolder" v-else>您尚未有任何結單優惠!</p>
    </div>
    <AreaLoading v-else></AreaLoading>
  </div>
</template>

<script>
export default {
  props: {
    participantId: {
      type: [String, Number],
      default: 0,
    }
  },
  emits: ['fromPromotionsData'],
  data() {
    return {
      serverToken: '',
      storeId: 0,
      participantInfo: {},
      storeDiscounts: {
        loading: false,
        data: []
      },
      selectStoreDiscount: null
    }
  },
  created() {
  },
  watch: {
    // 父層傳進來的 participantId
    participantId: {
      handler(val) {
        // 從父層傳遞過來的 participantId 因為 immediate: true 的關係
        // 所以觸發的比這裡的 created 還早
        this.initialization()
        this.getParticipantInfo()
      },
      immediate: true
    },
    // 選擇到的結單優惠
    selectStoreDiscount(val) {
      this.$emit('fromPromotionsData', val)
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 取得顧客資訊
    getParticipantInfo() {
      const vm = this
      const getParticipantInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        type: 13,
        ids: [this.participantId],
        methods: '{}'
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: getParticipantInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const participantInfo = res.data[0].objects[0]
            vm.participantInfo = participantInfo
            vm.getStoreInfo()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreDiscounts{getStoreVip,getStoreDiscountConditions,getCustomerGroups}}'
        }
      ]
      this.storeDiscounts = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.getStoreDiscounts(storeInfo.storeDiscounts)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理結單優惠
    getStoreDiscounts(storeDiscounts) {
      const now = new Date() * 1
      const participantVipId = this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      const participantCustomerGroupId = this.participantInfo.customerGroupId ? this.participantInfo.customerGroupId : 0
      storeDiscounts.forEach(item => {
        const discountVipId = item.storeVipId ? item.storeVipId : 0
        // console.log(`${item.id}: ${item.enableTime <= now},${now <= item.expirationTime},${item.pause},${item.siteObjectType === 'BILLHEADER'},${participantVipId >= discountVipId}`)
        if (item.enableTime <= now && now <= item.expirationTime && !item.pause && item.siteObjectType === 'BILLHEADER' && participantVipId >= discountVipId) {
          if (item.customerGroups.length === 0) {
            this.storeDiscounts.data.push(item)
          } else {
            const inCustomerGroupInterval = item.customerGroups.some(item => {
              return item.id === participantCustomerGroupId
            })
            if (inCustomerGroupInterval) this.storeDiscounts.data.push(item)
          } 
        }
      });
      this.storeDiscounts.loading = false
    },
  },
}
</script>